import React, { useEffect } from "react";
import classes from './mailCompleteTop.module.scss';
import Header from '../../molecules/header/Header';
import Footer from "../../molecules/footer/Footer";
import MailComplete from "../mail-complete/mailComplete";

const MailCompleteTop: React.FC = () => {

	useEffect(() => {
	  }, []);

	return (
	<>
	<Header></Header>
	<MailComplete></MailComplete>
	<Footer></Footer>
	</>
  );
};

export default MailCompleteTop;