// Top.jsx
import React from 'react';
import './TopPage.module.scss';
import TopHeader from '../../molecules/top/topHeader/TopHeader';
import Top from '../../molecules/top/topContent/TopContent';
import styles from './TopPage.module.scss';
import TopFooter from '../../molecules/footer/Footer';

function TopPage() {
  return (
    <>
      <div>
        <TopHeader />
      </div>
      <div className={styles.topWrapper}>
        <Top />
      </div>
      <div className={styles.topFooter}>
        <TopFooter />
      </div>
    </>
  );
}

export default TopPage;
