import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classes from './App.module.scss';

import AppProvider from './hooks/provider/AppProvider';

import TopPage from './pages/topPage/TopPage';
import QuestionTop from './pages/question-top/QuestionTop';
import ResultTop from './pages/result-top/ResultTop';
import MailCompleteTop from './pages/mail-complete-top/mailCompleteTop';
import Loading, { LoadingProvider } from './organisms/loading/Loading';

function App() {
  return (
    <div className={classes.appContainer}>
      <Helmet>
        <meta name="google" content="notranslate" />
      </Helmet>
      <LoadingProvider>
        <Loading />
        <AppProvider>
          {/* BrowserRouter */}
          <BrowserRouter basename='/owl'>
            {/* Routes コンポーネントでルーティングを設定 */}
            <Routes>
              <Route path={'/'} element={<TopPage />}></Route>
              <Route path={'/question'} element={<QuestionTop />}></Route>
              <Route path='/result' element={<ResultTop />} />
              <Route path={'/complete'} element={<MailCompleteTop />}></Route>
            </Routes>
          </BrowserRouter>
        </AppProvider>
      </LoadingProvider>
    </div>
  );
}

export default App;
