import Header from '../../molecules/header/Header';
import Question from '../question/Question';

interface QuestionTopProps {}

const QuestionTop = () => {
  return (
    <>
      <Header />
      <Question />
    </>
  );
};

export default QuestionTop;
