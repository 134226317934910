import React from 'react';
import classes from './Header.module.scss';
import headerLogo from '../../static/images/HeaderLogo.png';

function Header() {
  return (
    <div className={classes.headerContainer}>
      <img src={headerLogo} alt='Logo' />
    </div>
  );
}
export default Header;
