// Top.jsx
import React from 'react';
import logoImage from '../../../static/images/titleLogo.png';
import img1 from '../../../static/images/topChar1.png';
import img2 from '../../../static/images/topChar2.png';
import img3 from '../../../static/images/topChar3.png';
import img4 from '../../../static/images/topChar4.png';
import img5 from '../../../static/images/topChar5.png';
import img6 from '../../../static/images/topChar6.png';
import img7 from '../../../static/images/topChar7.png';
import img8 from '../../../static/images/topChar8.png';
import img9 from '../../../static/images/topChar9.png';
import styles from './TopHeader.module.scss';

function TopPage() {
  return (
    <div className='header'>
      <div className={styles.messageBar}>
        お金との関わり方 × 子どもとの関わり方
      </div>
      <div className={styles.logoContainer}>
        <img src={logoImage} alt='Logo' className={styles.topLogo} />
      </div>
      <div className={styles.subTitle}>9つのタイプ別に専門家がアドバイス！</div>
      {/* TODO：画像を囲んでいる四角が表現できていない部分 */}
      <div className={styles.imageContainer}>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img4} alt='Image 4' className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img6} alt='Image 6' className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img9} alt='Image 9' className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img1} alt='Image 1' className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img5} alt='Image 5' className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img3} alt='Image 3' className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img8} alt='image 8' className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img2} alt='Image 2' className={styles.image} />
          </div>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.imageBlock}>
            <img src={img7} alt='Image 7' className={styles.image} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopPage;
