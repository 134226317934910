import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScore } from '../../hooks/provider/ScoreContext';
import classes from './Question.module.scss';
import questionData from './question.json';
import charComment from '../../static/images/questionCharaComment.png';

//ロゴファイルの動的読み込み
const logos = Array.from({ length: 16 }).map((_, index) => {
  const logoNumber = index + 1;
  return require(`../../static/images/questionLogo${logoNumber}.png`);
});

//ロゴファイルの返却
const getQuestionLogoPath = (questionNumber: number) => {
  return logos[questionNumber - 1];
};

const TOTAL_QUESTIONS = 16;
const MAX_PROGRESS = 99.1; // プログレスバーをピッタリするために調整

const Question = () => {
  //Navigate
  const navigate = useNavigate();

  //ScoreProvider
  const { setScoreData } = useScore();

  //ProgressBar
  const progressContainerRef = useRef<HTMLDivElement | null>(null);
  const [progress, setProgress] = useState(0);
  const [startNumberPosition, setStartNumberPosition] = useState({});

  //QuestionComponent
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState<string[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<
    Array<{ questionNumber: number; answerNumber: number }>
  >([]);

  //回答結果保存
  const handleAnswerClick = (answerValue: string) => {
    const answerIdx = answers.findIndex((answer) => answer === answerValue);
    setSelectedAnswers((prev) => {
      // すでに選択された回答を見つける
      const existingAnswer = prev.find(
        (answer) => answer.questionNumber === currentQuestion
      );

      // 選択された回答がすでに存在する場合、それを更新する
      if (existingAnswer) {
        existingAnswer.answerNumber = answerIdx;
        return [...prev];
      }
      // それ以外の場合、新しい回答を追加する
      return [
        ...prev,
        { questionNumber: currentQuestion, answerNumber: answerIdx },
      ];
    });

    if (currentQuestion < TOTAL_QUESTIONS) {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  //１つ前に戻る
  const handlePreviousClick = () => {
    if (currentQuestion > 1) {
      setCurrentQuestion((prev) => prev - 1);
    }
  };

  const gradeOptions = [
    '未就学児',
    '幼稚園 年少',
    '幼稚園 年中',
    '幼稚園 年長',
    '小学校 １年生',
    '小学校 ２年生',
    '小学校 ３年生',
    '小学校 ４年生',
    '小学校 ５年生',
    '小学校 ６年生',
    '中学校 １年生',
    '中学校 ２年生',
    '中学校 ３年生',
    '高校 １年生',
    '高校 ２年生',
    '高校 ３年生',
    '大学 １年生',
    '大学 ２年生',
    '大学 ３年生',
    '大学 ４年生',
  ];

  type SchoolKey = '幼稚園' | '小学校' | '中学校' | '高校' | '大学';

  const schoolTypes: Record<SchoolKey, string[]> = {
    幼稚園: ['公立', '私立'],
    小学校: ['公立・国立', '私立'],
    中学校: ['公立・国立', '私立'],
    高校: ['公立・国立', '私立'],
    大学: ['公立・国立', '私立（文系）', '私立（理系）', '私立（医師学系）'],
  };

  type ChildInfo = {
    selectedGrade: string;
    futureSchoolChoices: string[];
    selectedFutureSchoolChoices: Record<SchoolKey, string>;
  };

  const [childrenInfo, setChildrenInfo] = useState<ChildInfo[]>([
    {
      selectedGrade: '',
      futureSchoolChoices: [],
      selectedFutureSchoolChoices: {
        幼稚園: '',
        小学校: '',
        中学校: '',
        高校: '',
        大学: '',
      },
    },
  ]);

  const handleRemoveChild = (childIndex: number) => {
    const updatedChildrenInfo = [...childrenInfo];
    updatedChildrenInfo.splice(childIndex, 1);
    setChildrenInfo(updatedChildrenInfo);
  };

  const handleGradeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    childIndex: number
  ) => {
    const updatedChildrenInfo = [...childrenInfo];
    updatedChildrenInfo[childIndex].selectedGrade = event.target.value;

    //未就学児はsplitできないため特別考慮
    let grade: string = event.target.value;
    let lastGrade: string = '';
    if (grade !== '未就学児') {
      lastGrade = grade.split(' ')[1];
    }

    let futureChoices: string[] = [];
    if (grade.startsWith('未就学児')) {
      futureChoices = ['幼稚園', '小学校', '中学校', '高校', '大学'];
    } else if (grade.startsWith('幼稚園')) {
      //最終学年の場合は、対象学校を含めない
      if (lastGrade === '年長') {
        futureChoices = ['小学校', '中学校', '高校', '大学'];
      } else {
        futureChoices = ['幼稚園', '小学校', '中学校', '高校', '大学'];
      }
    } else if (grade.startsWith('小学校')) {
      //最終学年の場合は、対象の学校タイプを含めない
      if (lastGrade === '６年生') {
        futureChoices = ['中学校', '高校', '大学'];
      } else {
        futureChoices = ['小学校', '中学校', '高校', '大学'];
      }
    } else if (grade.startsWith('中学校')) {
      //最終学年の場合は、対象の学校タイプを含めない
      if (lastGrade === '３年生') {
        futureChoices = ['高校', '大学'];
      } else {
        futureChoices = ['中学校', '高校', '大学'];
      }
    } else if (grade.startsWith('高校')) {
      //最終学年の場合は、対象の学校タイプを含めない
      if (lastGrade === '３年生') {
        futureChoices = ['大学'];
      } else {
        futureChoices = ['高校', '大学'];
      }
    } else if (grade.startsWith('大学')) {
      //最終学年の場合は、対象の学校タイプを含めない
      if (lastGrade === '４年生') {
        futureChoices = [];
      } else {
        futureChoices = ['大学'];
      }
    }

    let futureSchoolChoicesObj: Partial<Record<SchoolKey, string>> = {};
    // futureChoicesをもとに、futureSchoolChoicesObjを作成
    futureChoices.forEach((choice) => {
      futureSchoolChoicesObj[choice as SchoolKey] = '';
    });

    updatedChildrenInfo[childIndex].futureSchoolChoices = futureChoices;
    updatedChildrenInfo[childIndex].selectedFutureSchoolChoices =
      futureSchoolChoicesObj as Record<SchoolKey, string>;

    setChildrenInfo(updatedChildrenInfo);
  };

  const handleSchoolChoiseChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    childIndex: number,
    schoolChoice: SchoolKey
  ) => {
    const updatedChildrenInfo = [...childrenInfo];
    updatedChildrenInfo[childIndex].selectedFutureSchoolChoices[schoolChoice] =　event.target.value;
    setChildrenInfo(updatedChildrenInfo);
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    //childrenInfoのselectedFutureSchoolChoicesのどれか１つでも空白・初期値があれば非活性
    let isDisabled =
      !childrenInfo ||
      childrenInfo.some(
        (child) =>
          Object.values(child.selectedFutureSchoolChoices).some((value) => value === '') 
          || childrenInfo.some((child) => child.selectedGrade === 'Defalut')
      );
    setIsButtonDisabled(isDisabled);
  }, [childrenInfo]);

  //プログレスバー更新
  useEffect(() => {
    if (currentQuestion === 1) {
      setProgress(0);
    } else {
      setProgress((currentQuestion / TOTAL_QUESTIONS) * MAX_PROGRESS);
    }
  }, [currentQuestion]);

  useEffect(() => {
    const progressContainer = progressContainerRef.current;
    if (progressContainer) {
      const progressContainerWidth = progressContainer.offsetWidth;
      const newLeftValue = (progress / 100) * progressContainerWidth - 12;
      setStartNumberPosition({
        left: `${newLeftValue}px`,
      });
    }
  }, [progress, classes.progressContainer]);

  //回答リスト更新
  useEffect(() => {
    const currentData = questionData.questionList.find(
      (q) => q.questionNumber === currentQuestion
    );
    if (currentData) {
      setQuestion(currentData.question);
      const answerStrings: string[] = currentData.answerList.map(
        (a) => Object.values(a)[0]
      );
      setAnswers(answerStrings);
    }
  }, [currentQuestion]);

  //診断スタート
  const handleScoreClick = () => {
    setScoreData(childrenInfo, selectedAnswers);
    navigate('/result');
  };

  return (
    <div className={classes.questionContainer}>
      <div className={classes.progressContainer} ref={progressContainerRef}>
        <div
          className={classes.progressBar}
          style={{ width: `${progress}%` }}
        ></div>
        <span className={classes.startNumber} style={startNumberPosition}>
          {currentQuestion}
        </span>
        <span className={classes.endNumber}>16</span>
      </div>

      <div className={`${classes.question} notranslate`}>
        <p>Q{currentQuestion}</p>
        <p dangerouslySetInnerHTML={{ __html: question }}></p>
      </div>

      <div className={classes.logoContainer}>
        <img src={getQuestionLogoPath(currentQuestion)} alt='Logo' />
      </div>

      {currentQuestion === 16 ? (
        <>
          <div className={classes.logoContainer}>
            <img src={charComment} alt='Logo' />
          </div>

          {childrenInfo.map((child, childIndex) => (
            <div key={childIndex} className={classes.childrenContainer}>
              <div className={classes.header}>
                <h2>
                  <span>第{childIndex + 1}子</span>
                </h2>
              </div>
              {childIndex !== 0 && childIndex === childrenInfo.length - 1 && (
                <button
                  className={classes.childDeleteButton}
                  onClick={() => handleRemoveChild(childIndex)}
                >
                  <span>第{childIndex + 1}子の情報を削除</span>
                </button>
              )}

              <div className={classes.gradeContainer}>
                <p>現在の学年</p>
                <select
                  className={classes.select}
                  value={child.selectedGrade}
                  onChange={(e) => handleGradeChange(e, childIndex)}
                >
                  <option value='Defalut'>選択してください</option>
                  {gradeOptions.map((grade, index) => (
                    <option key={index} value={grade}>
                      {grade}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                {child.futureSchoolChoices.map((schoolChoice) => (
                  <div key={schoolChoice} className={classes.gradeContainer}>
                    <p>{schoolChoice}</p>
                    <select
                      className={classes.select}
                      value={
                        child.selectedFutureSchoolChoices[
                          schoolChoice as SchoolKey
                        ]
                      }
                      onChange={(e) =>
                        handleSchoolChoiseChange(
                          e,
                          childIndex,
                          schoolChoice as SchoolKey
                        )
                      }
                    >
                      <option value=''>選択してください</option>
                      {schoolTypes[schoolChoice as SchoolKey].map(
                        (type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <button
            className={classes.childAddButton}
            disabled={isButtonDisabled}
            onClick={() => {
              if (childrenInfo.length < 5) {
                setChildrenInfo([
                  ...childrenInfo,
                  {
                    selectedGrade: '',
                    futureSchoolChoices: [],
                    selectedFutureSchoolChoices: {
                      幼稚園: '',
                      小学校: '',
                      中学校: '',
                      高校: '',
                      大学: '',
                    },
                  },
                ]);
              }
            }}
          >
            +お子様を追加
          </button>
          <button
            className={classes.submitButton}
            disabled={isButtonDisabled}
            onClick={handleScoreClick}
          >
            診断結果を見る
          </button>
        </>
      ) : null}

      {currentQuestion !== 16 && (
        <div className={`${classes.answerButtonContainer} notranslate`} >
          {answers.map((answer, index) => (
            <button
              key={`question-${currentQuestion}-answer-${index}`} // 一意のkeyを生成
              onClick={() => handleAnswerClick(answer)}
              className={`${classes.selectButton} ${
                selectedAnswers[currentQuestion - 1]?.answerNumber === index
                  ? classes.selectedButton
                  : ''
              }`}
            >
              {answer}
            </button>
          ))}
        </div>
      )}

      {currentQuestion === 1 ? null : (
        <div className={classes.linkContainer} onClick={handlePreviousClick}>
          <p>ひとつ前に戻る</p>
        </div>
      )}
    </div>
  );
};

export default Question;
