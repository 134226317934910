import { ReactNode, createContext, useContext, useState, useEffect} from 'react';
import classes from './Loading.module.scss';
import Header from '../../molecules/header/Header';
import charLoading from '../../static/images/Loading.png';

export type ChangeLoadingState = (flag: boolean) => void;

export const LoadingContext = createContext<{
  loadingState: boolean;
  changeLoadingState: ChangeLoadingState;
}>({
  loadingState: false,
  changeLoadingState: (flag: boolean) => {},
});

export const LoadingProvider = ({ children }: { children: ReactNode }) => {

  const [loadingState, setLoaingState] = useState(false);

  const changeLoadingState = (flag: boolean) => {

    if (flag) {
      setLoaingState(flag);
      return;
    }
    setTimeout(() => setLoaingState(flag), 4000);
  }; 

  return (
    <LoadingContext.Provider value={{ loadingState, changeLoadingState }}>
      {children}
    </LoadingContext.Provider>
  );
};

const Loading = () => {

  const { loadingState } = useContext(LoadingContext);
  const [dot, setDot] = useState('');

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (loadingState) {
      // ドットのアニメーションを開始
      intervalId = setInterval(() => {
        setDot(prevDot => prevDot.length < 3 ? prevDot + '・' : '');
      }, 1000); // 3秒間で全てのコマを表示
    } else {
      // ローディング状態がfalseの場合はドットをリセット
      setDot('');
    }

    // コンポーネントのクリーンアップ関数
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [loadingState]);

  return (
    <>
      {loadingState && (
          <div className={classes.loadingContainer}>
          <Header></Header>
            <div className={classes.logoImageContainer}>
                  <img src={charLoading} alt="Logo" />
              </div>
              <p className={classes.message}>診断中{dot}</p>
          </div>
      )}
    </>
  );
};

export default Loading;
