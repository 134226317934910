export interface ScoreAttributes {
	offense?: number;
	defense?: number;
	free?: number;
	rule?: number;
}
  
export interface ScoreRuleSet {
	[key: number]: ScoreAttributes[];
}
  
export interface RequiredAnswersForBalance {
	[key: number]: number[];
}

export interface SchoolCost {
	annualTuitionFee: number;
	yearsOfStudy: number;
}
  
export interface SchoolTypeCosts {
	[schoolType: string]: SchoolCost;
}
  
export interface TuitionTable {
	[schoolLevel: string]: SchoolTypeCosts;
}

export interface GradeYearInfo {
	yearsUntilEighteen: number; // 18歳になるまでの年数
	yearsOfGraduation: number; // 現在の学年から卒業までの年数
}

// 各質問の回答のスコアリングルールセット
export const scoreRuleSet: ScoreRuleSet = {
		1: [{"offense": 0, "defense": 1}, {"offense": 0, "defense": 2}, {"offense": 1, "defense": 0}, {"offense": 2, "defense": 0}],
		2: [{"offense": 0, "defense": 0}, {"offense": 0, "defense": 2}, {"offense": 1, "defense": 1}, {"offense": 2, "defense": 0}],
		3: [{"offense": 0, "defense": 4}, {"offense": 0, "defense": 3}, {"offense": 0, "defense": 1}, {"offense": 0, "defense": 0}],
		4: [{"offense": 0, "defense": 4}, {"offense": 0, "defense": 3}, {"offense": 0, "defense": 3}, {"offense": 0, "defense": 0}],
		5: [{"offense": 0, "defense": 0}, {"offense": 0, "defense": 0}, {"offense": 0, "defense": 2}, {"offense": 0, "defense": 4}],
		6: [{"offense": 4, "defense": 3}, {"offense": 2, "defense": 1}, {"offense": 0, "defense": 0}, {"offense": 0, "defense": 0}],
		7: [{"offense": 3, "defense": 1}, {"offense": 3, "defense": 3}, {"offense": 1, "defense": 3}, {"offense": 0, "defense": 0}],
		8: [{"offense": 4, "defense": 0}, {"offense": 2, "defense": 0}, {"offense": 1, "defense": 0}, {"offense": 0, "defense": 0}],
		9: [{"free": 4, "rule": 4}, {"free": 3, "rule": 3}, {"free": 1, "rule": 1}, {"free": 0, "rule": 0}],
		10: [{"free": 4, "rule": 4}, {"free": 3, "rule": 3}, {"free": 1, "rule": 1}, {"free": 0, "rule": 0}],
		11: [{"free": 1, "rule": 2}, {"free": 4, "rule": 4}, {"free": 3, "rule": 1}, {"free": 0, "rule": 0}],
		12: [{"free": 4, "rule": 4}, {"free": 0, "rule": 0}, {"free": 0, "rule": 2}, {"free": 2, "rule": 0}],
		13: [{"free": 4, "rule": 4}, {"free": 2, "rule": 2}, {"free": 1, "rule": 1}, {"free": 0, "rule": 0}],
		14: [{"free": 0, "rule": 3}, {"free": 3, "rule": 0}, {"free": 3, "rule": 3}, {"free": 0, "rule": 0}],
		15: [{"free": 0, "rule": 4}, {"free": 1, "rule": 3}, {"free": 3, "rule": 3}, {"free": 4, "rule": 4}]
};

// 各質問におけるバランス型判定に必須な回答のリスト
export const requiredAnswersForMoneyBalance: RequiredAnswersForBalance = {
	3: [0, 1],
	4: [0],
	5: [2, 3],
	6: [0],
	7: [0, 1, 2],
	8: [0],
};

export const requiredAnswersForEducationBalance: RequiredAnswersForBalance = {
	9: [0, 1],
	10: [0, 1],
	11: [1],
	12: [0],
	13: [0, 1],
	14: [2],
	15: [2, 3]
};

export const tuitionTable: TuitionTable = {
	"幼稚園": {
	  "私立": {
		annualTuitionFee: 31,
		yearsOfStudy: 3
	  },
	  "公立": {
		annualTuitionFee: 17,
		yearsOfStudy: 3
	  }
	},
	"小学校": {
	  "公立・国立": {
		annualTuitionFee: 35,
		yearsOfStudy: 6
	  },
	  "私立": {
		annualTuitionFee: 167,
		yearsOfStudy: 6
	  }
	},
	"中学校": {
	  "公立・国立": {
		annualTuitionFee: 54,
		yearsOfStudy: 3
	  },
	  "私立": {
		annualTuitionFee: 144,
		yearsOfStudy: 3
	  }
	},
	"高校": {
	  "公立・国立": {
		annualTuitionFee: 51,
		yearsOfStudy: 3
	  },
	  "私立": {
		annualTuitionFee: 105,
		yearsOfStudy: 3
	  }
	},
	"大学": {
	  "公立・国立": {
		annualTuitionFee: 60,
		yearsOfStudy: 4
	  },
	  "私立（文系）": {
		annualTuitionFee: 102,
		yearsOfStudy: 4
	  },
	  "私立（理系）": {
		annualTuitionFee: 137,
		yearsOfStudy: 4
	  },
	  "私立（医師学系）": {
		annualTuitionFee: 592,
		yearsOfStudy: 4 // 医学部の場合、便宜上4年とする
	  }
	}
  };

export const gradeYearMapping: { [grade: string]: GradeYearInfo } = {
	"未就学児": { yearsUntilEighteen: 16, yearsOfGraduation: 0 },
	"幼稚園 年少": { yearsUntilEighteen: 15, yearsOfGraduation: 1 },
	"幼稚園 年中": { yearsUntilEighteen: 14, yearsOfGraduation: 2 },
	"幼稚園 年長": { yearsUntilEighteen: 13, yearsOfGraduation: 3 },
	"小学校 １年生": { yearsUntilEighteen: 12, yearsOfGraduation: 1 },
	"小学校 ２年生": { yearsUntilEighteen: 11, yearsOfGraduation: 2 },
	"小学校 ３年生": { yearsUntilEighteen: 10, yearsOfGraduation: 3 },
	"小学校 ４年生": { yearsUntilEighteen: 9, yearsOfGraduation: 4 },
	"小学校 ５年生": { yearsUntilEighteen: 8, yearsOfGraduation: 5 },
	"小学校 ６年生": { yearsUntilEighteen: 7, yearsOfGraduation: 6 },
	"中学校 １年生": { yearsUntilEighteen: 6, yearsOfGraduation: 1 },
	"中学校 ２年生": { yearsUntilEighteen: 5, yearsOfGraduation: 2 },
	"中学校 ３年生": { yearsUntilEighteen: 4, yearsOfGraduation: 3 },
	"高校 １年生": { yearsUntilEighteen: 3, yearsOfGraduation: 1 },
	"高校 ２年生": { yearsUntilEighteen: 2, yearsOfGraduation: 2 },
	"高校 ３年生": { yearsUntilEighteen: 1, yearsOfGraduation: 3 },
	// 大学の学年については18歳になるまでの年数は1年で固定
	"大学 １年生": { yearsUntilEighteen: 1, yearsOfGraduation: 1 },
	"大学 ２年生": { yearsUntilEighteen: 1, yearsOfGraduation: 2 },
	"大学 ３年生": { yearsUntilEighteen: 1, yearsOfGraduation: 3 },
	"大学 ４年生": { yearsUntilEighteen: 1, yearsOfGraduation: 4 },
  };
  
  