import { useEffect,useState} from 'react';
import startChar from '../../../static/images/TopButtonChar.png';
import styles from './TopContent.module.scss';
import { useNavigate } from 'react-router-dom';

interface TopProps {}

const Top = () => {
  const navigate = useNavigate();
  const [isStoredTypePresent, setIsStoredTypePresent] = useState(false);

  useEffect(() => {
    const storedType = localStorage.getItem('selectedDignoseType');
    //過去の履歴が取得できない場合は非活性
    setIsStoredTypePresent(!!storedType);
  }, []); // 初回マウントのみ

  //診断画面に直接遷移
  const handleScoreClick = () => {
    navigate('/result');
  };

  return (
    <>
      <div className={styles.subMessage}>対象：未就学児～小学生の保護者</div>
      <div className={styles.imageWrapper}>
        <img
          src={startChar}
          alt='startChar'
          className={styles.buttonCharImage}
        />
        {/* TODO：ボタンのatoms化 */}
        <button className={styles.button} onClick={() => navigate('question')}>
          診断スタート
        </button>
      </div>
      {isStoredTypePresent && (
      <div className={styles.linkContainer} onClick={handleScoreClick}>
        <p>過去の診断結果はこちら</p>
        </div>
      )}
    </>
  );
};

export default Top;
