import { ReactNode } from 'react';
import { ScoreProvider } from './ScoreContext';


const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ScoreProvider>
      {children}
    </ScoreProvider>
  );
};

export default AppProvider;
