import React, { useEffect } from "react";
import classes from './mailComplete.module.scss';
import completeChara from '../../static/images/completeChara.png';

const MailComplete: React.FC = () => {

	useEffect(() => {
	  }, []);

	return (
	<>
    	<div className={classes.logoContainer}>
        	<img src={completeChara} alt='Logo' />
        </div>
    	<div className={classes.completeTitle}>
			メールを送信しました
		</div>
    	<div className={classes.completeMessage}>
			<span>みらいい「noreply@miraii.jp」からメルマガ会員登録完了のメールをお送りしました。数分経っても届かない場合は、迷惑メールフォルダをご確認ください
			</span>
		</div>

    	<div className={classes.completeMessageWaringTitle}>
			<p>
				メールが届きませんか？
			</p>
		</div>
    	<div className={classes.completeMessageWaring}>
		<span>
			入力いただいたメールアドレスが登録されていないか、登録いただいた際にメールアドレスが誤っていた可能性がございます。
			お手数ですが
 			<a href="https://docs.google.com/forms/d/e/1FAIpQLSfNGAFHzhMvncqxI28BhyQWYs6APKuQjZdZ2S2zdLLZ3nbZew/viewform" target="_blank">こちらのリンク</a>
			から再度お試しください。
		</span>
		</div>
	</>
  );
};

export default MailComplete;