import TopFooter from '../../molecules/footer/Footer';
import Result from '../result/Result';
import styles from '../topPage/TopPage.module.scss';

interface ResultTopProps {}

const ResultTop = () => {
  return (
    <>
      <Result />
    </>
  );
};

export default ResultTop;
