// Header.jsx
import React from 'react';
import footerImage from '../../static/images/TitleLogoFooter.png';
import exportImage from '../../static/images/exportIcon.png';
import styles from './Footer.module.scss';

function Footer() {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerTitle}>
        <img src={footerImage} alt='footerImage' />
      </div>
      <div className={styles.footerNav}>
        <div className={styles.divider}></div>
        <div className={styles.navMenu}>運営</div>
        <div className={styles.divider}></div>
        <div
          className={styles.navMenuWrapper}
          onClick={() => window.open('https://corp.innoviot.co.jp')}
        >
          <div className={styles.navMenu}>株式会社イノビオット</div>
          <img
            src={exportImage}
            alt='exportImage'
            className={styles.exportImage}
          />
        </div>
        <div className={styles.divider}></div>
        <div
          className={styles.navMenuWrapper}
          onClick={() => window.open('https://shindan.miraii.jp/terms')}
        >
          <div className={styles.navMenu}>利用規約</div>
          <img
            src={exportImage}
            alt='exportImage'
            className={styles.exportImage}
          />
        </div>
        <div className={styles.divider}></div>
        <div
          className={styles.navMenuWrapper}
          onClick={() => window.open('https://shindan.miraii.jp/privacy')}
        >
          <div className={styles.navMenu}>プライバシーポリシー</div>
          <img
            src={exportImage}
            alt='exportImage'
            className={styles.exportImage}
          />
        </div>
        <div className={styles.divider}></div>
        <div
          className={styles.navMenuWrapper}
          onClick={() => window.open('https://miraii.jp/')}
        >
          <div className={styles.navMenu}>
            みらいい｜子どもの未来を切りひらくメディア
          </div>
          <img
            src={exportImage}
            alt='exportImage'
            className={styles.exportImage}
          />
        </div>
        <div className={styles.divider}></div>
        <div
          className={styles.navMenuWrapper}
          onClick={() => window.open('https://serv.miraii-academia.com/')}
        >
          <div className={styles.navMenu}>
            みらいいアカデミア｜オンラインプログラミング教室
          </div>
          <img
            src={exportImage}
            alt='exportImage'
            className={styles.exportImage}
          />
        </div>
        <div className={styles.copyright}>©️2023 innoviot ltc.</div>
      </div>
    </div>
  );
}

export default Footer;
