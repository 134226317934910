  import React, { useState, useEffect, useContext } from 'react';
  import { useNavigate } from 'react-router-dom';
  import classes from './Result.module.scss';
  import resultData from './result.json';
  import { LoadingContext } from '../..//organisms/loading/Loading';
  import { useScore } from '../../hooks/provider/ScoreContext';
  import Header from '../../molecules/header/Header';
  import TopFooter from '../../molecules/footer/Footer';
  import MailForm  from '../mail-form/mailForm';

  //ロゴファイルの動的読み込み
  const logos = Array.from({ length: 9 }).map((_, index) => {
    const typeNumber = index + 1;
    return require(`../../static/images/resultLogo${typeNumber}.png`);
  });

  const messageLogos = Array.from({ length: 10 }).map((_, index) => {
    const typeNumber = index + 1;
    return require(`../../static/images/messageLogo${typeNumber}.png`);
  });

  const getResultLogoPath = (typeNumber: number) => {
    return logos[typeNumber - 1];
  };

  const getMessgaeLogoPath = (typeNumber: number) => {
    return messageLogos[typeNumber - 1];
  };

  //テキスト文章の改行コード変換
  const getNewLineText = (text: string) => {
    return text.split('\n').map((str, index,array) => {
      // 改行のみの行を検出
      if (str === '') {
        // 2つの改行を挿入して、それぞれにユニークなkeyを割り当てる
        return (
          <React.Fragment key={`empty-${index}`}>
            <br key={`br1-${index}`} />
          </React.Fragment>
        );
      }
      // 文字列が含まれる行では、spanで囲み、最後でなければ改行を追加
      return (
        <span key={`line-${index}`}>
          {str}
          {index !== array.length - 1 && <br />}
        </span>
      );
    });
  };

  const getColorClassForChild = (type:string, currentType:string) => {
    if (type === currentType) {
      switch (type) {
        case 'Rule': return 'blue';
        case 'Balance': return 'green';
        case 'Free': return 'orange';
        default: return 'gray';
      }
    }
    return 'gray';
  };

  const getColorClassForMoney = (type:string, currentType:string) => {
    if (type === currentType) {
      switch (type) {
        case 'Aggressive': return 'orange';
        case 'Balance': return 'green';
        case 'Conservative': return 'blue';
        default: return 'gray';
      }
    }
    return 'gray';
  };

  const Result = () => {
    const { loadingState, changeLoadingState } = useContext(LoadingContext);
    const navigate = useNavigate();

    // ScoreContextから計算結果を受け取る
    const { isDiagnosed, selectedDignoseType, totalTuitionFee, totalTuitionFeeYearlyBased, totalTuitionFeeMonthlyBased } = useScore();

    const [localSelectedDignoseType, setLocalSelectedDignoseType]                 = useState(selectedDignoseType)
    const [localTotalTuitionFee, setLocalTotalTuitionFee]                         = useState(totalTuitionFee);
    const [localTotalTuitionFeeYearlyBased, setLocalTotalTuitionFeeYearlyBased]   = useState(totalTuitionFeeYearlyBased);
    const [localTotalTuitionFeeMonthlyBased, setLocalTotalTuitionFeeMonthlyBased] = useState(totalTuitionFeeMonthlyBased);

    const initialData = resultData.resultList.find((q) => q.dignoseType === selectedDignoseType) || resultData.resultList[0];
    const [currentData, setCurrentData] = useState(initialData);

    useEffect(() => {

      let dignoseTypeToUse = 0;

      if (!isDiagnosed) {
        // ローカルストレージからのデータを読み込む
        let localSelectedDignoseTypeVal = localStorage.getItem('selectedDignoseType');

        let localTotalTutitonFeeVal     = localStorage.getItem('totalTuitionFee');
        let localtotalTuitionFeeYearlyBasedVal = localStorage.getItem('totalTuitionFeeYearlyBased');
        let localtotalTuitionFeeMonthlyBasedVal = localStorage.getItem('totalTuitionFeeMonthlyBased');

        setLocalSelectedDignoseType(localSelectedDignoseTypeVal ? parseInt(localSelectedDignoseTypeVal, 10) : 0);
        setLocalTotalTuitionFee(
          localTotalTutitonFeeVal ? parseFloat(parseFloat(localTotalTutitonFeeVal).toFixed(2)) : 0
        );
        setLocalTotalTuitionFeeYearlyBased(
          localtotalTuitionFeeYearlyBasedVal ? parseFloat(parseFloat(localtotalTuitionFeeYearlyBasedVal).toFixed(2)) : 0
        );
        setLocalTotalTuitionFeeMonthlyBased(
          localtotalTuitionFeeMonthlyBasedVal ? parseFloat(parseFloat(localtotalTuitionFeeMonthlyBasedVal).toFixed(2)) : 0
        );

        if (localSelectedDignoseTypeVal === "0" ) {
          navigate('/');
          return;
        }
        dignoseTypeToUse = localSelectedDignoseTypeVal ? parseInt(localSelectedDignoseTypeVal, 10) : 0;

      }else{

        if ( selectedDignoseType === 0 ) {
          navigate('/');
          return;  
        }

        dignoseTypeToUse = selectedDignoseType;

      }

      const data = resultData.resultList.find((q) => q.dignoseType === dignoseTypeToUse);
      if (data) {
        setCurrentData(data);
      }

      changeLoadingState(true);

      changeLoadingState(false);

    }, []); 
    
    return (
      <>
        {!loadingState && (
          <>
            <div className={classes.topContainer}>
              <Header />
              <div className={classes.typeContainer}>
                <p className={classes.sub}>{currentData.typeTitle}</p>
                <span className={classes.main}>{currentData.typeName}</span>
                <span className={classes.sub}>タイプ</span>
              </div>
              <div className={classes.typeExplanation}>
                <p>{currentData.typeFeature}</p>
              </div>
              <div className={`${classes.logoContainer} image`}>
                <img src={getResultLogoPath(currentData.dignoseType)} alt='Logo' />
              </div>
            </div>
            <div className={classes.barTitle}>お金との関わり方</div>
            <div className={classes.barContainer}>
              <div
                className={`${classes.barLeft} ${
                  classes[getColorClassForMoney('Conservative', currentData.moneyTendency)]
                }`}
              >
                守り
              </div>
              <div
                className={`${classes.barCenter} ${
                  classes[getColorClassForMoney('Balance', currentData.moneyTendency)]
                }`}
              >
                バランス
              </div>
              <div
                className={`${classes.barRight} ${
                  classes[getColorClassForMoney('Aggressive', currentData.moneyTendency)]
                }`}
              >
                攻め
              </div>
            </div>
            <div className={classes.barTitle}>子供との関わり方</div>
            <div className={classes.barContainer}>
              <div
                className={`${classes.barLeft} ${
                  classes[getColorClassForChild('Rule', currentData.childTendency)]
                }`}
              >
                ルール
              </div>
              <div
                className={`${classes.barCenter} ${
                  classes[getColorClassForChild('Balance', currentData.childTendency)]
                }`}
              >
                バランス
              </div>
              <div
                className={`${classes.barRight} ${
                  classes[getColorClassForChild('Free', currentData.childTendency)]
                }`}
              >
                フリー
              </div>
            </div>
            <div className={classes.preMessgaeContainer}>
              <div className={classes.logoContainer}>
                  <img src={getMessgaeLogoPath(10)} alt='Logo' />
              </div>
              <div className={classes.preMessage}>
              1,000名以上の相談実績がある【資産づくりの専門家】の経験と学術的な知見に基づいて診断しました！
              <br></br>
              内容を詳しく説明していきますね♪              
              </div>
            </div>
            <div className={classes.space}></div>
            <div className={classes.messageBox}>
              <h2 className={classes.messageBoxTitle}>お金との関わり方の傾向</h2>
              <div className={classes.logoContainer}>
                <img src={getMessgaeLogoPath(1)} alt='Logo' />
              </div>
              <h3 className={classes.messageBoxSubtitle}>
                {currentData.moneyTendencyTitle}
              </h3>
              <p className={classes.messageBoxContent}>
                {getNewLineText(currentData.moneyTendencyExplanation)}
              </p>
            </div>
            <div className={classes.messageBox}>
              <h2 className={classes.messageBoxTitle}>
                子どもとの関わり方の傾向
              </h2>
              <div className={classes.logoContainer}>
                <img src={getMessgaeLogoPath(2)} alt='Logo' />
              </div>
              <h3 className={classes.messageBoxSubtitle}>
                {currentData.childTendencyTitle}
              </h3>
              <p className={classes.messageBoxContent}>
                {getNewLineText(currentData.childTendencyExplanation)}
              </p>
            </div>
            <div className={classes.messageBox}>
              <h2 className={classes.messageBoxTitle}>
                子どもの金銭感覚への影響
              </h2>
              <div className={classes.logoContainer}>
                <img src={getMessgaeLogoPath(3)} alt='Logo' />
              </div>
              <h3 className={classes.messageBoxSubtitle}>
                {currentData.impactTitle}
              </h3>
              <p className={classes.messageBoxContent}>
                {getNewLineText(currentData.impactExplanation)}
              </p>
            </div>
            <div className={classes.messageBox}>
              <h2 className={classes.messageBoxTitle}>
                将来必要な教育資金の目安
              </h2>
              <div className={classes.logoContainer}>
                <img src={getMessgaeLogoPath(4)} alt='Logo' />
              </div>
              <div className={classes.messageSubBoxContent}>
                <p className={classes.messageSubBoxTitle}>
                  これから必要になる教育費の総額
                </p>
                <span className={classes.messageBoxNumber}>
                  {isDiagnosed ? totalTuitionFee : localTotalTuitionFee}
                </span>
                <span className={classes.messageBoxNumber}>万円</span>
              </div>
              <div className={classes.messageSubBoxContent}>
                <p className={classes.messageSubBoxTitle}>
                  子ども全員が高校卒業するまでの積立額
                </p>
                <span className={classes.messageBoxNumberTitle}>月額</span>
                <span className={classes.messageBoxNumber}>
                  {isDiagnosed ? totalTuitionFeeMonthlyBased : localTotalTuitionFeeMonthlyBased}
                </span>
                <span className={classes.messageBoxNumber}>万円</span>
                <span className={classes.messageBoxNumberTitle}>年間</span>
                <span className={classes.messageBoxNumber}>
                  {isDiagnosed ? totalTuitionFeeYearlyBased : localTotalTuitionFeeYearlyBased}
                </span>
                <span className={classes.messageBoxNumber}>万円</span>
              </div>
            </div>
            <div className={classes.messageBox}>
              <h2 className={classes.messageBoxTitle}>おすすめアクション</h2>
              <div className={classes.logoContainer}>
                <img src={getMessgaeLogoPath(5)} alt='Logo' />
              </div>
              <div className={classes.messageSubBoxContent}>
                <p className={classes.messageSubBoxTitle}>
                  お金との関わり方をより良くするアクション
                </p>
                <p className={classes.messageSubBoxTitleExplanation}>
                  {getNewLineText(currentData.suggestedMoneyActionTitle)}
                </p>
              </div>
              <p className={classes.messageBoxContent}>
                {getNewLineText(currentData.suggestedMoneyActionExplanation)}
              </p>
              <div className={classes.messageSubBoxContent}>
                <p className={classes.messageSubBoxTitle}>
                  子どもとの関わり方をより良くするアクション
                </p>
                <p className={classes.messageSubBoxTitleExplanation}>
                  {currentData.suggestedChildActionTitle}
                </p>
              </div>
              <p className={classes.messageBoxContent}>
                {getNewLineText(currentData.suggestedChildActionTitleExplanation)}
              </p>
            </div>
            {/*
            <div className={classes.logoContainer}>
              <img src={getMessgaeLogoPath(6)} alt='Logo' />
            </div>
            <div className={classes.space}></div>
            <div className={classes.mailContainer}>
                <br></br>
                <span className={classes.mailHeaderSeparate}>
                ＼
                </span>
                <span className={classes.mailSubHeader1}>
                お得
                </span>
                <span className={classes.mailSubHeader2}>
                  がいっぱい
                </span>
                <span className={classes.mailHeaderSeparate}>
                ／
                </span>
                <p className={classes.mailHeader}>
                  メルマガ会員募集中！
                </p>
                <div className={classes.imageContainer}>
                  <div className={classes.topImages}>
                    <img src={getMessgaeLogoPath(7)} alt='Logo' />
                    <img src={getMessgaeLogoPath(8)} alt='Logo' />
                  </div>
                  <div className={classes.topImages}>
                    <img src={getMessgaeLogoPath(9)} alt='Logo' />
                  </div>
                </div>
            </div>
            <div className={classes.mailForm}>
              <MailForm />
            </div>
            */}
            <div className={classes.footer}>
              <TopFooter />
            </div>
          </>
        )}
      </>
    );
  };
  export default Result;
