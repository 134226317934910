import React, { createContext, useContext, useState,useEffect } from 'react';
import {tuitionTable,gradeYearMapping,scoreRuleSet, requiredAnswersForMoneyBalance,requiredAnswersForEducationBalance } from './ScoreParameters';

interface ScoreAttributes {
  offense?: number;
  defense?: number;
  free?: number;
  rule?: number;
}

type SchoolKey = "幼稚園" | "小学校" | "中学校" | "高校" | "大学";

type ChildInfo = {
    selectedGrade: string;
    futureSchoolChoices: string[];
    selectedFutureSchoolChoices: Record<SchoolKey, string>;
};

type ScoreContextType = {
  isDiagnosed: boolean;
  childrenInfo: ChildInfo[];
  selectedAnswers: Array<{ questionNumber: number, answerNumber: number }>;
  selectedDignoseType: number;
  totalTuitionFee:number;
  totalTuitionFeeYearlyBased:number,
  totalTuitionFeeMonthlyBased:number,
  setScoreData: (childrenInfo: ChildInfo[], selectedAnswers: Array<{ questionNumber: number, answerNumber: number }>) => void;
};

const defaultContextValue: ScoreContextType = {
  isDiagnosed: false,
  childrenInfo: [],
  selectedAnswers: [],
  selectedDignoseType: 0,
  totalTuitionFee:0,
  totalTuitionFeeYearlyBased:0,
  totalTuitionFeeMonthlyBased:0,
  setScoreData: () => {},
};

const calculateScores = (answers:Array<{ questionNumber: number, answerNumber: number }> ) => {
  let scores = {
    "offense": 0,
    "defense": 0,
    "free": 0,
    "rule": 0
  };

  answers.forEach(answer => {
    const currentQuestionScore = scoreRuleSet[answer.questionNumber][answer.answerNumber];
    for (const key in currentQuestionScore) {
      if (key in scores) {
        const scoreKey = key as keyof ScoreAttributes;
        scores[scoreKey] += currentQuestionScore[scoreKey] ?? 0;
      }
    }
  });

  return scores;
};

//Context
const ScoreContext = createContext<ScoreContextType>(defaultContextValue);
export const useScore = () => useContext(ScoreContext);

//Provider
export const ScoreProvider = ({ children }: { children: React.ReactNode }) => {

  const [childrenInfo, setChildrenInfo] = useState<ChildInfo[]>([]);
  const [selectedAnswers, setSelectedAnswers]         = useState<Array<{ questionNumber: number; answerNumber: number }>>([]);
  const [isDiagnosed, setIsDiagnosed] = useState<boolean>(false);
  const [selectedDignoseType, setSelectedDignoseType] = useState<number>(0);
  const [totalTuitionFee, setTotalTuitionFee]         = useState<number>(0);
  const [totalTuitionFeeYearlyBased, setTotalTuitionFeeYearlyBased] = useState<number>(0);
  const [totalTuitionFeeMonthlyBased, setTotalTuitionFeeMonthlyBased] = useState<number>(0);
  
  const setScoreData = (newChildrenInfo: ChildInfo[], newSelectedAnswers: Array<{ questionNumber: number, answerNumber: number }>) => {

    setChildrenInfo(newChildrenInfo);
    setSelectedAnswers(newSelectedAnswers);

    //診断結果から算出
    const moneyTendencyType     = computeMoneyTendencyType(newSelectedAnswers);
    const educationTendencyType = computeEducationTendencyType(newSelectedAnswers);
    const newDignoseType        = computeDignoseType(moneyTendencyType,educationTendencyType);
    setSelectedDignoseType(newDignoseType);
    localStorage.setItem('selectedDignoseType', newDignoseType.toString());

    //子供情報から教育費用を算出
    const {
      totalTuitionFee,
      totalTuitionFeeYearlyBased,
      totalTuitionFeeMonthlyBased
    } = computeTuitionFee(newChildrenInfo);
    
    setTotalTuitionFee(totalTuitionFee);
    setTotalTuitionFeeYearlyBased(totalTuitionFeeYearlyBased);
    setTotalTuitionFeeMonthlyBased(totalTuitionFeeMonthlyBased);
    localStorage.setItem('totalTuitionFee', totalTuitionFee.toString());
    localStorage.setItem('totalTuitionFeeYearlyBased', totalTuitionFeeYearlyBased.toString());
    localStorage.setItem('totalTuitionFeeMonthlyBased', totalTuitionFeeMonthlyBased.toString());

    setIsDiagnosed(true);
  };

  const computeTuitionFee = (newChildrenInfo: ChildInfo[]): {
      totalTuitionFee: number,
      totalTuitionFeeYearlyBased: number,
      totalTuitionFeeMonthlyBased: number
      }  => { 

      let totalTuitionFee: number = 0;
      let totalYearsToSave: number = 0;
    
      newChildrenInfo.forEach(child => {

        let childTuitionFee: number = 0;

        const gradeInfo = gradeYearMapping[child.selectedGrade];
  
        // 高校卒業までの年数を取得。学年が見つからない場合は0を使用。高校３年以上は便宜的に"1"が設定
        const yearsToSave = gradeInfo ? gradeInfo.yearsUntilEighteen : 0;
        totalYearsToSave += yearsToSave;
        
        Object.entries(child.selectedFutureSchoolChoices).forEach(([schoolKey, schoolType]) => {
          const tuitionInfo = tuitionTable[schoolKey]?.[schoolType];
          if (tuitionInfo) {
            
            // 子供が現在所属している学校レベルを取得
            let currentSchoolLevel = "";
            if (child.selectedGrade !== "未就学児"){
              currentSchoolLevel = child.selectedGrade.split(' ')[0];
            }

            // selectedGradeがschoolKeyと一致する場合。対象学年から卒業までの年数を計算
            // selectedGradeがschoolKeyと一致しない場合、全学年分の学費を計算
            const yearsToCalculate = currentSchoolLevel === schoolKey ? (tuitionInfo.yearsOfStudy - gradeInfo.yearsOfGraduation) : tuitionInfo.yearsOfStudy;
            //　決まった年数✖️１年あたりの学費から対象学校の教育費用を算出す
            childTuitionFee += tuitionInfo.annualTuitionFee * yearsToCalculate;
          }
        });
        totalTuitionFee += childTuitionFee;
      });
      
      // 卒業までの年数がゼロの場合は、ゼロ除算が出来ないため全ての値を初期値で返却
      if (totalYearsToSave === 0) {
        return { totalTuitionFee: 0, totalTuitionFeeYearlyBased: 0, totalTuitionFeeMonthlyBased: 0 };
      }
  
      // 年間および月間の積立額を計算(年間は切り捨て、月間は小数部第2位を切り捨て)
      const totalTuitionFeeYearlyBased  = Math.floor(totalTuitionFee / totalYearsToSave);
      const totalTuitionFeeMonthlyBased = parseFloat((totalTuitionFeeYearlyBased / 12).toFixed(1));

      //totalTuitionFeeを再計算して、端数の誤差を修正
      totalTuitionFee = totalTuitionFeeYearlyBased * totalYearsToSave;

      return {
        totalTuitionFee,
        totalTuitionFeeYearlyBased,
        totalTuitionFeeMonthlyBased
      };
    };

  const computeMoneyTendencyType = (answers: Array<{ questionNumber: number, answerNumber: number }>): string => {

    const scores = calculateScores(answers)
    let isBalanceTypeCandidate = true;
    answers.forEach(answer => {
      // バランス型の判定: 必須回答をしているかチェック
      if (answer.questionNumber in requiredAnswersForMoneyBalance) {
        isBalanceTypeCandidate = isBalanceTypeCandidate && requiredAnswersForMoneyBalance[answer.questionNumber].includes(answer.answerNumber);
      }
    });

    // バランス型の判定
    if (isBalanceTypeCandidate && scores.offense >= 13 && scores.defense >= 15) {
      return "balanced";
    } else if (scores.offense > scores.defense) {
      return "aggressive";
    } else {
      return "defensive";
    }
  };

  const computeEducationTendencyType = (answers: Array<{ questionNumber: number, answerNumber: number }>): string => {

    const scores = calculateScores(answers);

    let isBalanceTypeCandidate = true;
    answers.forEach(answer => {
      // バランス型の判定: 必須回答をしているかチェック
      if (answer.questionNumber in requiredAnswersForEducationBalance) {
        isBalanceTypeCandidate = isBalanceTypeCandidate && requiredAnswersForEducationBalance[answer.questionNumber].includes(answer.answerNumber);
      }
    });

    // バランス型の判定
    if (isBalanceTypeCandidate && scores.free >=  24 && scores.rule >= 24) {
      return "balanced";
    } else if (scores.free > scores.rule) {
      return "free";
    } else {
      return "rule";
    }
  };

  const computeDignoseType = (moneyTendencyType: string,educationTendencyType:string)  => {
    if ( moneyTendencyType === "aggressive" && educationTendencyType === "rule"){
      return 1;
    } else if  ( moneyTendencyType === "aggressive" && educationTendencyType === "balanced"){
      return 4;
    } else if  ( moneyTendencyType === "aggressive" && educationTendencyType === "free"){
      return 7;
    } else if  ( moneyTendencyType === "balanced" && educationTendencyType === "rule"){
      return 2;
    } else if  ( moneyTendencyType === "balanced" && educationTendencyType === "balanced"){
      return 5;
    } else if  ( moneyTendencyType === "balanced" && educationTendencyType === "free"){
      return 8;
    } else if  ( moneyTendencyType === "defensive" && educationTendencyType === "rule"){
      return 3;
    } else if  ( moneyTendencyType === "defensive" && educationTendencyType === "balanced"){
      return 6;
    } else if  ( moneyTendencyType === "defensive" && educationTendencyType === "free"){
      return 9;
    } else{
      return 0;
    }
  }

  return (
    <ScoreContext.Provider value={{ childrenInfo, selectedAnswers, selectedDignoseType, setScoreData, totalTuitionFee, totalTuitionFeeYearlyBased, totalTuitionFeeMonthlyBased, isDiagnosed }}>
    {children}
    </ScoreContext.Provider>
  );
};